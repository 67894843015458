import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid,
  Button,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import skrill from "../../images/SkrillLogo.png";
import "./infoStyle.css";
import { Link } from "react-router-dom";
import { endPoints, PaymentEndpoints } from "../../constant/Environment";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/Reducers/GlobalReducer/globalSlice";
import { useSnackbarContext } from "../../component/SnackbarContext";
import { getAllData, addData, addBetData } from "../../Utility/API";
import { SkrillEnv } from "../../constant/SkrillEnv";
import Loader from "../../component/Loader";
import StripeModal from "./StripeModal";
import VisaImg from "../../images/visa.svg";
import DiscoverImg from "../../images/discover.svg";
import MastercardImg from "../../images/master.svg";
import Amazon_PayImg from "../../images/amazon.png";
const PaymentModal = (props) => {
  const {
    open,
    onClose,
    selecteNewPackage,

    getCoinPackageList,
    GetUserById,
    paymentMode,
  } = props;
  const [stripeOpen, setStripeOpen] = useState(false);
  const theme = useTheme();
  // console.log("selecteNewPackage", selecteNewPackage);
  const { goldcoins, purchase_price } = selecteNewPackage;
  const loginData = useSelector((state) => state.auth.IsLoginData);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarContext();

  const stripeClose = () => {
    setStripeOpen(false);
  };

  const stripeShow = (paymentMode ?? []).some(item => item?.paymentMode === "Stripe" && item?.isActive) || paymentMode.length == 0;

  const skrillShow = (paymentMode ?? []).some(item => item?.paymentMode === "Skrill" && item?.isActive) || paymentMode.length == 0;

  const AmazonPayShow = (paymentMode ?? []).some(item => item?.paymentMode === "AmazonPay" && item?.isActive) || paymentMode.length == 0;
  
  const payBySkrill = () => {
    if (selecteNewPackage) {
      const merchantRefNum = RandomRefNum(`spinsaga_${loginData?.idUser}`);
      SavePackageInfo(
        selecteNewPackage.purchase_price,
        merchantRefNum,
        selecteNewPackage
      );
    }
  };

  const RandomRefNum = (prefix) => {
    const randomNum = Math.floor(Math.random() * 900000) + 100000;
    return `${prefix}_${randomNum}`;
  };

  const SavePackageInfo = async (
    depositamount,
    referenceNo,
    selectedPackage
  ) => {
    let url = `${PaymentEndpoints.apiBaseUrl}${PaymentEndpoints.api.SKRILL_ADD}`;
    setLoading(true);
    let data = {
      id_skrill_wallet_transaction: 0,
      merchantRefNum: referenceNo,
      id_user: loginData?.idUser,
      amount: Number((depositamount * 100).toFixed(2)),
      currencyCode: "USD",
      paymentHandleToken: "",
      pay_from_email: "",
      goldcoins: selectedPackage.goldcoins,
      sagacoins: selectedPackage.sagacoins,
      packagename: selectedPackage.package_name,
      packageId: selectedPackage.id,
    };
    try {
      let response = await addBetData(url, data);
      if (response?.data?.status == "success") {
        onClose();
        // `${SkrillEnv.keys}`;
        // skrill modal script
        window.paysafe.checkout.setup(
          SkrillEnv.keys,
          {
            currency: "USD", // 4 currencies currently supported by Paysafe Checkout - "USD", "CAD", "EUR", "GBP"
            // amount: depositamount * 100, // This qualifies as USD $ 100.00. Multiply Payment Amount by 100 and supply here.
            amount: Number((depositamount * 100).toFixed(2)), // This qualifies as USD $ 100.00. Multiply Payment Amount by 100 and supply here.
            payout: false, // payout: true --> for Withdrawal (standalone credits); payout: false --> for Payments/Deposit
            payoutConfig: {
              // payoutConfig section required only when payout: true [for Withdrawal cases].
              maximumAmount: 100000000,
            },
            locale: "en_US",
            simulator: "EXTERNAL",
            imageUrl:
              "https://hosted.paysafe.com/checkout/resource/demo-store/images/logo.png", // Supply Your Logo URL here.
            environment: SkrillEnv.Env, // environment: "LIVE" --> Production, "TEST" --> test environment for customers to try out Checkout & its features
            buttonColor: "#66cc99", // feel free to change the color of buttons (RGB value)
            companyName: "Bettdraft", // Supply Your Company Name here
            holderName: "John Smith", // Supply Customer's Name here
            customer: {
              firstName: loginData?.name,
              lastName: loginData?.surname,
              email: loginData?.email,
              phone: loginData?.mobile,
              dateofBirth: loginData?.birthDate,
            },
            billingAddress: {
              // Supply customer's billing Address here.
              nickName: loginData?.name,
              street: loginData?.address,
              city: loginData?.town,
              zip: loginData?.zipCode,
              country: loginData?.country,
              state: loginData?.stateCode,
            },
            merchantRefNum: referenceNo, // Will be unique and must keep changing every transaction
            canEditAmount: false, // Makes the payment amount editable on Checkout screen. Make it false to open Checkout with a fixed non-editable amount.
            merchantDescriptor: {
              dynamicDescriptor: "XYZ",
              phone: "1234567890",
            },
            displayPaymentMethods: [
              "neteller",
              "skrill",
              "paysafecard",
              "paysafecash",
              "instantach",
              "paypal",
              "card",
              "vippreferred",
              "sightline",
              "ach",
              "eft",
            ],
            // displayPaymentMethods : Array serves two purposes. You can use it to restrict the payment methods that a customer can see.
            // You can also use it to order the payment methods based on your preference.
            // If this field is present, the customer will only see those payment methods in the order specified, hence,
            // Ensure that you provide all payment methods if you are using it only for the purpose of payment method ordering.
            paymentMethodDetails: {
              // Please read the Checkout Objects Documentation on developer.paysafe.com .....
              paysafecard: {
                // .... for details on paymentMethodDetails (including all supported mandatory and optional fields)
                consumerId: "123456",
              },
              paysafecash: {
                consumerId: "123456",
              },
              sightline: {
                consumerId: "12341231256",
                SSN: "123456789",
                last4ssn: "6789",
                //accountId: "1009688222"  // Supply Account ID only if multiple accounts are configured with same payment method
              },
              vippreferred: {
                consumerId: "120288765",
                //accountId: "1679688456"  // Supply Account ID only if multiple accounts are configured with same payment method
              },
              card: {
                accountId: "284006353",
                // Supply Account ID only if multiple accounts are configured with same payment method
              },
              skrill: {
                consumerId: loginData?.email,
                emailSubject: "Payout for Greg Neteller",
                emailMessage: "You Have Received Payout of $100.",
              },
              instantach: {
                consumerId: "john.doe@email.com",
                paymentId: "3aeb9c63-6386-46a3-9f8e-f452e722228a",
                emailSubject: "Instant ACH Payout",
                emailMessage:
                  "Your Instant ACH Payout request has been processed",
              },
              neteller: {
                consumerId: "netellertest_EUR@neteller.com",
                recipientDescription: "logo_url_alt_text",
                logoUrl: "http://www.paysafe.com/icon.jpg",
              },
            },

            threeDs: {
              //Required for 3DS2
              authenticationPurpose: "PAYMENT_TRANSACTION",
              deviceChannel: "BROWSER",
              merchantUrl:
                "https://api.qa.paysafe.com/checkout/v2/index.html#/desktop",
              messageCategory: "PAYMENT",
            },
          },
          function (instance, error, result) {
            if (result && result.paymentHandleToken) {
              setLoading(false);
              instance.close();
              SkrillPay(
                result?.amount,
                referenceNo,
                result?.paymentHandleToken,
                selectedPackage
              );
            } else if (result.paymentHandleToken == null) {
              SkrillFailed(depositamount, referenceNo);
              setLoading(false);
              instance.close();
            } else {
              // alert(error);
              console.log("error");
              showErrorSnackbar(error);
              setLoading(false);
            }
            if (result.token) {
              //alert(""Payment Token (Returned By Paysafe GW): "" + result.token);
              console.log(result.token);
              // pass this token to server to make the payment or payout using Paysafe Payments API.
            }
          },
          function (stage, expired) {
            setLoading(false);
            switch (stage) {
              case "PAYMENT_HANDLE_NOT_CREATED": // Handle the scenario
              case "PAYMENT_HANDLE_CREATED": // Handle the scenario
              case "PAYMENT_HANDLE_REDIRECT": // Handle the scenario
              case "PAYMENT_HANDLE_PAYABLE": // Handle the scenario
              default: // Handle the scenario
            }
          },
          function (instance, amount, paymentMethod) {
            setLoading(false);
            // use parameters amount and payment method to do riskCheck
            // Applicable to only Cards, PaySafe Cash, VIPP and Sightline
            if (amount == 2000) {
              instance.decline("Please use amount less than 100");
              // showErrorSnackbar("Please use amount less than 100");
            } else {
              instance.accept();
            }
          }
        );
      } else {
        showErrorSnackbar(response.data.message);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      showErrorSnackbar(
        "Issue connecting to server (error code 15). Please contact support for further assistance."
      );
    }
  };

  const SkrillPay = async (
    depositamount,
    referenceNo,
    tokenId,
    selectedPackage
  ) => {
    let url = `${PaymentEndpoints.apiBaseUrl}${PaymentEndpoints.api.DEPOSIT_PAYMENT}`;

    let data = {
      merchantRefNum: referenceNo,
      amount: depositamount,
      id_user: loginData?.idUser,
      accountId: "284006353",
      paymentHandleToken: tokenId,
      currencyCode: "USD",
      settleWithAuth: true,
      goldcoins: selectedPackage?.goldcoins,
      sagacoins: selectedPackage?.sagacoins,
      packagename: selectedPackage?.package_name,
      pakageId: selectedPackage?.id,
    };
    try {
      setLoading(true);
      let response = await addBetData(url, data);

      if (response?.data?.status == "success") {
        showSuccessSnackbar(response?.data?.message);
        GetUserById();
        getCoinPackageList();
      } else {
        showErrorSnackbar(response?.data?.message);
      }
    } catch (err) {
      console.log(err);
      showErrorSnackbar(
        "Issue connecting to server (error code 15). Please contact support for further assistance."
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const SkrillFailed = async (depositamount, referenceNo) => {
    let url = `${PaymentEndpoints.apiBaseUrl}${PaymentEndpoints.api.FAILED_DEPOSIT}`;

    let data = {
      merchantRefNum: referenceNo,
      amount: depositamount,
      id_user: loginData?.idUser,
      accountid: "string",
      paymentHandleToken: "string",
      currencyCode: "string",
      settleWithAuth: "string",
      status: "string",
      description: "string",
      tstatus: 0,
    };
    try {
      setLoading(true);
      let response = await addBetData(url, data);
      setLoading(false);
      if (response?.data?.status == "success") {
        showSuccessSnackbar(response?.data?.message);
        GetUserById();
        getCoinPackageList();
      } else if (response?.data?.status == "failure") {
        showErrorSnackbar(response?.data?.message);
      } else {
        showErrorSnackbar(response?.data?.message);
      }
    } catch (err) {
      console.log(err);
      showErrorSnackbar(
        "Issue connecting to server (error code 15). Please contact support for further assistance."
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // stripe package submit code
  const submitPackageData = async () => {
    const merchantRefNum = RandomRefNum(`spinsaga_${loginData?.idUser}`);
    let payload = {
      merchantRefNum: merchantRefNum,
      packageId: selecteNewPackage.id,
      usersession: loginData?.sessionId,
    };

    try {
      setLoading(true);
      let url = `${PaymentEndpoints.apiBaseUrl}${PaymentEndpoints.api.ADD_PAYMENT_METHOD}`;
      const packageResult = await addBetData(url, payload);
      // console.log("packageResult", packageResult?.data?.status);
      setLoading(false);
      if (packageResult?.data?.status) {
        onClose();
        setStripeOpen(true);
      } else {
        onClose();
        setStripeOpen(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <StripeModal
        open={stripeOpen}
        onClose={stripeClose}
        selecteNewPackage={selecteNewPackage}
      />
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
        id="infoModal"
        className="info-modal Purchase_modal"
        BackdropProps={{
          style: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        }}
      >
        {loading && <Loader loading={loading} />}
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
        >
          <Typography variant="h6" component="h3" sx={{ flexGrow: 1 }}>
            Complete Purchase
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ color: theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <hr />
        <DialogContent
          sx={{
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
        >
          <Grid container spacing={2}>
            {/* Gold Coins Section */}
            <Grid item xs={12}>
              <div className="purchase_main">
                <div className="purchase_left">
                  <Typography
                    className="small_p"
                    sx={{
                      color: theme.palette.custom.l_gray,
                    }}
                    variant="h6"
                  >
                    Order Summary
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      color: theme.palette.custom.l_gray,
                    }}
                  >
                    {goldcoins} Gold Coins
                  </Typography>
                </div>
                <div className="purchase_right">
                  <Typography
                    variant="h3"
                    sx={{
                      color: theme.palette.custom.white,
                    }}
                  >
                    <span
                      sx={{
                        color: theme.palette.custom.l_gray,
                      }}
                    >
                      Total
                    </span>{" "}
                    ${purchase_price}
                  </Typography>
                </div>
              </div>
              <hr className="devided_hr" />
              
              {/* pay with card  */}
              {stripeShow && (
              <Typography
                className="purchase_card"
                sx={{
                  backgroundColor: theme.palette.custom.lgray,
                }}
              >
                <Typography
                  className="purchase_header"
                  sx={{
                    backgroundColor: theme.palette.custom.dgray,
                    color: theme.palette.text.primary,
                  }}
                >
                  Credit/Debit Card
                </Typography>

                <Typography
                  className="purchase_pay"
                  sx={{
                    backgroundColor: theme.palette.custom.gray3,
                    color: theme.palette.text.primary,
                  }}
                >
                  <div className="flex_img">
                    <img src={VisaImg} alt="visa" />
                    <img alt="master.svg" src={MastercardImg} />
                    <img alt="discover.svg" src={DiscoverImg} />
                  </div>
                  <Button
                    size="small"
                    sx={{
                      background: theme.palette.custom.red,
                      color: theme.palette.custom.white,
                    }}
                    onClick={() => {
                      submitPackageData();
                    }}
                  >
                    Pay Now
                  </Button>
                </Typography>
              </Typography>
                ) } 

              {/* pay with amazon */}
              {AmazonPayShow && (
              <Typography
                className="purchase_card"
                sx={{
                  backgroundColor: theme.palette.custom.lgray,
                  marginTop: 2,
                }}
              >
                <Typography
                  className="purchase_header"
                  sx={{
                    backgroundColor: theme.palette.custom.dgray,
                    color: theme.palette.text.primary,
                  }}
                >
                  Amazon Pay
                </Typography>

                <Typography
                  className="purchase_pay"
                  sx={{
                    backgroundColor: theme.palette.custom.gray3,
                    color: theme.palette.text.primary,
                  }}
                >
                  <div className="flex_img">
                    <img src={Amazon_PayImg} alt="visa" style={{height:'40px',width:'52px'}} />
        
                  </div>
                  <Button
                    size="small"
                    sx={{
                      background: theme.palette.custom.red,
                      color: theme.palette.custom.white,
                    }}
                    onClick={() => {
                      submitPackageData();
                    }}
                  >
                    Pay Now
                  </Button>
                </Typography>
              </Typography>
              )}


              {/* pay with skrill */}
              {skrillShow &&
              (
              <Typography
                className="purchase_card"
                sx={{
                  backgroundColor: theme.palette.custom.lgray,
                  marginTop: 2,
                }}
              >
                <Typography
                  className="purchase_header"
                  sx={{
                    backgroundColor: theme.palette.custom.dgray,
                    color: theme.palette.text.primary,
                  }}
                >
                  Banking
                </Typography>

                <Typography
                  className="purchase_pay"
                  sx={{
                    backgroundColor: theme.palette.custom.gray3,
                    color: theme.palette.text.primary,
                  }}
                >
                  <div className="flex_img">
                    <img src={skrill} alt="skrill" style={{ width: "50px" }} />
                  </div>
                  <Button
                    size="small"
                    sx={{
                      background: theme.palette.custom.red,
                      color: theme.palette.custom.white,
                    }}
                    onClick={() => {
                      payBySkrill();
                    }}
                  >
                    Pay Now
                  </Button>
                </Typography>
              </Typography>
                  )}

              <div className="modal_bottom">
                <Typography
                  sx={{
                    color: theme.palette.custom.l_gray,
                  }}
                >
                  At <Link to="/"> SpinSaga Casino</Link> it is ALWAYS FREE to
                  enter or win our Sweepstakes games. No Purchase Necessary.
                  Void where prohibited by law. Find out more in our Sweepstakes
                  Rules. Terms of Service apply.
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  This charge will appear on your card as SpinSaga Inc.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentModal;
