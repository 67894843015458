import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Modal,
} from "@mui/material";

import { useTheme } from "@emotion/react";
import ReferImg from "../../images/refafriend-comp-2.png";
import DailyClaimImg from "../../images/gold-sweepstake-coins.svg";
import VIPImg from "../../images/VIPPO.png";
import ReferFriendImg from "../../images/Referfreind.png";

import img5 from "../../images/coin1.webp";
import svg from "../../images/svg.svg";
import { endPoints, PaymentEndpoints } from "../../constant/Environment";
import { useSelector } from "react-redux";
import { setLoading } from "../../redux/Reducers/GlobalReducer/globalSlice";
import { useDispatch } from "react-redux";
import { useSnackbarContext } from "../../component/SnackbarContext";
import { getAllData, addData, addBetData } from "../../Utility/API";
import { setLoginData } from "../../redux/Reducers/AuthReducer/authSplice";
import SweepStakeTypography from "../../component/SweepStakeTypography";
import { useNavigate } from "react-router-dom";
import coinimg1 from "../../images/mainpackage.png";
import coinimg2 from "../../images/secondpackage.png";
import PaymentModal from "./PaymentModal";
import CoinBackImg from "../../images/coin-back.svg";
import CoinBigImg from "../../images/coin-big.svg";
import CoinSmallImg from "../../images/coin-small.svg";
import LeftImg from "../../images/tag-left.svg";
import RightImg from "../../images/tag-right.svg";
import DailyReward from "../DailyReward";
import CopiedButton from "./CopiedButton";
import TimerButton from "./TimerButton";

const BuyCoinsNew = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const loginData = useSelector((state) => state.auth.IsLoginData);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarContext();

  const { dailyLoginClaim, nextDailyLogin, validation, packagecount } =
    loginData;

    // console.log('dailyLoginClaim',dailyLoginClaim)
  const [mainItems, setMainItems] = useState([]);

  const [specialItem, setSpecialItem] = useState([])

  const SpecialOffer = [
    {
      package_name: "25000 Gold Coin Pakage",
      creative: VIPImg,
      goldcoins: "25000",
      sagacoins: "15",
      purchase_price: "9.99",
      max_claim_perday: 3,
      exclude_package: [],
      isPopular: 1,
      isFeature: 0,
      created_on: "2024-06-17T16:30:59.000+0000",
      updated_on: "2025-02-10T15:09:38.000+0000",
      max_claim: 4,
      days: 1,
      isDisabled: false,
      id: 8,
      isactive: 1,
    },
  ];

  const DummyData = [
    {
      id: 1,
      name: "Refer A Friend",
      message: "Free SC 5 ",
      buttonName: "Copy Link",
      image: ReferFriendImg,
    },
    {
      id: 2,
      name: "Free Daily Rewards",
      message: "Free SC",
      buttonName: "Claim Now",
      image: DailyClaimImg,
    },
  ];
  const [payModal, setPayModal] = useState(false);

  const [paymentMode, setPaymentMode] = useState([]);

  const getCoinPackageList = async (list) => {
    let url = `${endPoints.api.GET_PACKAGE}`;
    dispatch(setLoading(true));
    await getAllData(url)
      .then((response) => {
        dispatch(setLoading(false));
        if (response?.status === "success" && response?.data?.length > 0) {
          const excludePackageIds = response?.data[0]?.exclude_package || [];

          const filteredPackages = response?.data?.filter(
            (item) => !excludePackageIds.includes(item?.id)
          );
          

          setMainItems(filteredPackages);
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.error(error);
      });
  };

  const [selecteNewPackage, setSelectdNewPackage] = useState({});

  const handleOpen = (data) => {
    setSelectdNewPackage(data);
    // setOpen(true);
    setPayModal(true);
  };

  const GetUserById = async () => {
    try {
      const url = `${endPoints.api.GET_BY_USERID}/${loginData?.idUser}`;
      const response = await getAllData(url);
      dispatch(setLoginData({ user: response?.data, isAuthenticated: true }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCoinPackageList();
    GetUserById();
  }, []);

  const payModalClose = () => {
    setPayModal(false);
  };

  const fetchPaymentMethods = async () => {
    try {
      let url = `${endPoints.api.GET_PAYMENTS}`;
      const response = await getAllData(url);
      if (response?.status === "success") {
        setPaymentMode(response?.data);
      } else {
        showErrorSnackbar(response?.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const [openDailyRewards, setOpenDailyRewards] = useState(false);
  const openCustomButton = (item) => {
    if (item.name == "Free Daily Rewards" || item.id == 2) {
      setOpenDailyRewards(true);
    }
  };

  const closeRewardModal = (flag) => {
    if (flag) {
      GetUserById();
      setOpenDailyRewards(false);
    } else {
      setOpenDailyRewards(false);
    }
  };

  return (
    <>
      <main className="inner-body inner_space">
        <PaymentModal
          open={payModal}
          onClose={payModalClose}
          selecteNewPackage={selecteNewPackage}
          GetUserById={GetUserById}
          getCoinPackageList={getCoinPackageList}
          paymentMode={paymentMode}
        />
        {openDailyRewards && (
          <DailyReward
            openDailyRewards={openDailyRewards}
            closeRewardModal={closeRewardModal}
          />
        )}

        {/* first section hide right now */}
        {/* <div className="icon_heading">
          <Typography sx={{ color: theme.palette.custom.white_gray }}>
            <span
              style={{
                marginRight: "10px",
              }}
            >
              <img src={coinimg1} />
            </span>
            Special Offers
          </Typography>
        </div>

        <Grid
          className="buy_row"
          container
          spacing={{ xs: 5, sm: 4, md: 2 }}
          style={
            {
            
            }
          }
        >
          <div className="button_scroll">
            <div className="c_scroll ">
              <div className="custom_row">
                              
                {mainItems?.map((item, index) => {
                  if(item.isSpecial == 1){
                    return (
                      <main className="custom_col" key={item.id}>
                        <Card
                          className="c_card middle_card"
                          sx={{
                            background: theme.palette.custom.lgray,
                            borderColor: theme.palette.custom.redblue_border,
                          }}
                        >
                          <div className="card_label">
                            <p>
                              <span>Free</span>
                              <span>SC {item.sagacoins}</span>
                            </p>
                            <img src={svg} alt="icon" />
                          </div>
  
                          <div className="buy_card1">
                            <div className="right_half">
                              <CardMedia
                                component="img"
                                height="100%"
                                image={`${endPoints?.imageCDN}${item.creative}`}
                              />
                            </div>
                            <div className="left_half">
                              <Typography
                                className="f18 f-bold"
                                sx={{ color: theme.palette.custom.white_gray }}
                              >
                                {item.goldcoins} GC
                              </Typography>
  
                              <Button
                                className="best_btn"
                                size="small"
                                sx={{
                                  background: theme.palette.custom.red,
                                  color: theme.palette.custom.white,
                                }}
                                disabled={item?.isDisabled}
                                onClick={() => {
                                  handleOpen(item);
                                }}
                              >
                                <span>BUY FOR</span> ${item.purchase_price}
                              </Button>
                            </div>
                          </div>
                          <Typography
                              className="bottom_middle"
                              size="small"
                              sx={{
                                background: theme.palette.custom.dark_d,
                                color: theme.palette.custom.white,
                              }}
                            >
                              +{item.sagacoins} Free SC
                            </Typography>
                        </Card>
                      </main>
                    );
                  }else{
                    return null
                  }
               
                })}

                {DummyData.map((ele) => {
                  if (
                    (validation == "1" && packagecount > 0 && ele.id == 2) ||
                    ele.id == 1
                  ) {
                    return (
                      <main className="custom_col">
                        <Card
                          className="c_card middle_card"
                          sx={{
                            background: theme.palette.custom.lgray,
                            borderColor: theme.palette.custom.redblue_border,
                          }}
                        >
                          <div className="card_label">
                            <p>
                              <span>{ele.message} </span>
                            </p>
                            <img src={svg} alt="icon" />
                          </div>

                          <div className="buy_card1">
                            <div className="right_half">
                              <CardMedia
                                component="img"
                                height="100%"
                                image={ele.image}
                              />
                            </div>
                            <div className="left_half">
                              <Typography
                                className="f18 f-bold"
                                sx={{ color: theme.palette.custom.white_gray }}
                              >
                                {ele.name}
                              </Typography>

                              {ele.name == "Refer A Friend" || ele.id == 1 ? (
                                <CopiedButton />
                              ) : (
                                <Button
                                  className="best_btn"
                                  size="small"
                                  sx={{
                                    background: theme.palette.custom.red,
                                    color: theme.palette.custom.white,
                                  }}
                                  onClick={() => {
                                    openCustomButton(ele);
                                  }}
                                  disabled={dailyLoginClaim}
                                >
                                  <TimerButton
                                    initialTime={nextDailyLogin}
                                    isDailyLoginClaim={dailyLoginClaim}
                                  />
                                </Button>
                              )}
                            </div>
                          </div>
                        </Card>
                      </main>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
        </Grid> */}

        <div className="icon_heading">
          <Typography sx={{ color: theme.palette.custom.white_gray }}>
            <span
              style={{
                marginRight: "10px",
              }}
            >
              <img src={coinimg1} />
            </span>{" "}
            Most Popular
          </Typography>
        </div>
        <Grid className="buy_row" container spacing={{ xs: 2, sm: 4, md: 4 }}>
          {mainItems?.map((item, index) => {
            if (item.isPopular == 1 && item.isSpecial == 0) {
              return (
                <>
                  <Grid item xs={8} md={9}>
                    <Card
                      className="c_card long-card"
                      sx={{
                        background: theme.palette.custom.gray3,
                        borderColor: theme.palette.custom.btn_border,
                      }}
                    >
                      <div className="long_img_coin">
                        <img
                          class="big_coin"
                          src={CoinBigImg}
                          alt="Coin icon"
                        />
                        <img
                          class="small_coin"
                          src={CoinSmallImg}
                          alt="Coin icon"
                        />
                        <img
                          class="back_coin"
                          src={CoinBackImg}
                          alt="Coin icon"
                        />
                      </div>
                      {item.isFeature == 1 ? (
                        <div className="green_label">
                          <Box
                            sx={{
                              color: theme.palette.custom.blackWhite,
                            }}
                          >
                            <img src={RightImg} alt="tag-right-part" />
                            <Typography
                              sx={{ background: theme.palette.custom.green01 }}
                              variant="p"
                              class="span"
                            >
                              Featured
                            </Typography>
                            <img src={LeftImg} alt="tag-left-part" />
                          </Box>
                        </div>
                      ) : null}

                      <div className="half_flex">
                        <Typography
                          className="f18 f-bold"
                          sx={{ color: theme.palette.custom.white_gray }}
                        >
                          {item?.goldcoins} GC
                        </Typography>

                        <Button
                          size="small"
                          sx={{
                            background: theme.palette.custom.red,
                            color: theme.palette.custom.white,
                          }}
                          disabled={item?.isDisabled}
                          onClick={() => {
                            handleOpen(item);
                          }}
                        >
                          ${item.purchase_price}
                        </Button>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <Typography
                      sx={{ background: theme.palette.custom.dark_d }}
                      className="right_carddiv"
                    >
                      {/* <Button
                        size="small"
                        sx={{
                          background: theme.palette.custom.gray3,
                          color: theme.palette.custom.white,
                        }}
                      >
                        Daily Transfer :{" "}
                        {item.max_claim_perday == 0
                          ? "N/A"
                          : item.max_claim_perday >= 100
                          ? "Unlimited"
                          : item.max_claim_perday == 1
                          ? `${item.max_claim_perday} Time`
                          : `${item.max_claim_perday} Times`}
                      </Button> */}
                      <Button
                        size="large"
                        sx={{
                          background: theme.palette.custom.greenhalf,
                          color: theme.palette.custom.green01,
                        }}
                      >
                        Free SC {item.sagacoins}
                      </Button>
                    </Typography>
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}
        </Grid>

        <div className="icon_heading">
          <Typography
            sx={{ color: theme.palette.custom.white_gray, marginTop: 5 }}
          >
            <span
              style={{
                marginRight: "10px",
              }}
            >
              <img src={coinimg2} />
            </span>{" "}
            More coin packages below
          </Typography>
        </div>

        <Grid className="buy_row" container spacing={{ xs: 2, sm: 4, md: 4 }}>
          {mainItems?.map((item, index) => {
            if (item.isPopular == 1 || item.isSpecial == 1) {
              return null;
            } else {
              return (
                <>
                  <Grid item xs={8} md={9}>
                    <Card
                      className="c_card long-card"
                      sx={{
                        background: theme.palette.custom.gray3,
                        borderColor: theme.palette.custom.btn_border,
                      }}
                    >
                      <div className="long_img_coin">
                        <img
                          class="big_coin"
                          src={CoinBigImg}
                          alt="Coin icon"
                        />
                        <img
                          class="small_coin"
                          src={CoinSmallImg}
                          alt="Coin icon"
                        />
                        <img
                          class="back_coin"
                          src={CoinBackImg}
                          alt="Coin icon"
                        />
                      </div>

                      {/* <div className="green_label">
                        <Box
                          sx={{
                            color: theme.palette.custom.blackWhite,
                          }}
                        >
                          <img
                            src={RightImg}
                            alt="tag-right-part"
                          />
                          <Typography
                            sx={{ background: theme.palette.custom.green01 }}
                            variant="p"
                            class="span"
                          >
                            MEGA 1ST OFFER
                          </Typography>
                          <img
                            src={LeftImg}
                            alt="tag-left-part"
                          />
                        </Box>
                      </div> */}

                      <div className="half_flex">
                        <Typography
                          className="f18 f-bold"
                          sx={{ color: theme.palette.custom.white_gray }}
                        >
                          {item?.goldcoins} GC
                        </Typography>
                        <Button
                          size="small"
                          sx={{
                            background: theme.palette.custom.red,
                            color: theme.palette.custom.white,
                          }}
                          disabled={item?.isDisabled}
                          onClick={() => {
                            handleOpen(item);
                          }}
                        >
                          ${item.purchase_price}
                        </Button>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <Typography
                      sx={{ background: theme.palette.custom.dark_d }}
                      className="right_carddiv"
                    >
                      {/* <Button
                        size="small"
                        sx={{
                          background: theme.palette.custom.gray3,
                          color: theme.palette.custom.white,
                        }}
                      >
                        Daily Transfer :
                        {item.max_claim_perday == 0
                          ? "N/A"
                          : item.max_claim_perday >= 100
                          ? "Unlimited"
                          : item.max_claim_perday == 1
                          ? `${item.max_claim_perday} Time`
                          : `${item.max_claim_perday} Times`}
                      </Button> */}
                      <Button
                        size="large"
                        sx={{
                          background: theme.palette.custom.greenhalf,
                          color: theme.palette.custom.green01,
                        }}
                      >
                        Free SC {item.sagacoins}
                      </Button>
                    </Typography>
                  </Grid>
                </>
              );
            }
          })}
        </Grid>

        <Grid className="buy_footer" container>
          <Grid item container justifyContent="center" spacing={2}>
            <Grid item>
              <Button
                size="small"
                sx={{
                  background: theme.palette.custom.greenbtn,
                  color: theme.palette.custom.white,
                }}
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                <span style={{ textTransform: "capitalize" }}>
                  24/7 Customer Help
                </span>
              </Button>
            </Grid>

            <Grid item>
              <Button
                size="small"
                sx={{
                  background: theme.palette.custom.blue,
                  color: theme.palette.custom.white,
                }}
                onClick={() => {
                  navigate("/purchase-history");
                }}
              >
                <span style={{ textTransform: "capitalize" }}>
                  Purchase History
                </span>
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <SweepStakeTypography></SweepStakeTypography>
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default BuyCoinsNew;
